import * as React from "react"
import { useState, useCallback } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import FileUpload from "../components/form/FileUpload"
import { IconMail2, IconPhone, IconPlace } from "../components/icons/Icons"
import Map from "../components/common/Map"
import AdditionalForms from "../components/common/AdditionalForms"
import InputField from "../components/form/InputField"
import FormWrapper from "../components/form/FormWrapper"
import useFormState from "../components/form/hooks/useFormState"
import AsAdvertisedOn from "../components/common/AsAdvertisedOn"
import HaveQuestions from "../components/common/HaveQuestions"
import HowItWorks from "../components/common/HowItWorks"

const DiamondQuoteForm = () => {
  const CANONICAL_PATH_NAME = "/what-is-my-diamond-worth/"
  if (typeof window !== `undefined` && window && window.location.pathname !== CANONICAL_PATH_NAME) {
    window.history.replaceState(null, null, CANONICAL_PATH_NAME + (window.location?.search || ""))
  }
  const [form, setForm, updateForm] = useFormState({
    name: "",
    phone: "",
    email: "",
    description: "",
    color: "",
    clarity: "",
    carat: "",
    photos: [],
  })
  const updateUploadedFiles = useCallback(
    files =>
      setForm(f => {
        return { ...f, photos: files }
      }),
    [setForm]
  )
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        title="What is my diamond worth? diamond Quote Form"
        description="Discover the true value of your diamond at SellUsYourJewelry. Our experts will give you top dollar for your precious stone. Find out your (Keyword) today!"
        canonical={CANONICAL_PATH_NAME}
      />

      <section
        id="Diamond Instant Quote Form"
        className="bg-gradient-to-tl from-white to-slate-300 pt-20 md:pt-5 lg:pt-0 py-10 md:py-20"
      >
        <div className="text-center text-3xl font-black text-gray-600 md:text-5xl xl:text-6xl w-full inline-block pb-5 lg:py-10 uppercase px-5">
          THE SOLUTION <span className="font-normal">to selling your jewelry</span>
        </div>
        <div className="flex flex-col items-center justify-center lg:flex-row">
          <div className="flex w-10/12 mx-auto flex-col justify-center items-center xl:px-20">
            <FormWrapper
              className={"bg-white p-5 2xl:w-10/12 pl-5 2xl:pl-[25%] relative rounded-xl shadow-xl"}
              name={"What is my diamond worth"}
              action={"/thank-you/"}
              form={form}
              category={"ring"}
            >
              <div className="flex items-center flex-col md:flex-row 2xl:min-w-[500px] w-full 2xl:w-1/3 justify-center 2xl:absolute -bottom-[6%] 2xl:right-[calc(60%+5vw)] z-10 px-10">
                <StaticImage
                  src={"../images/sell-my-diamond-form-header.png"}
                  alt={"Watch Quote Form image"}
                  loading={"lazy"}
                  className="w-full md:w-1/2 2xl:w-full"
                />
                <h1 className="text-center w-full text-3xl px-5 uppercase 2xl:hidden flex justify-center items-center">
                  WHAT IS MY diamond WORTH?
                </h1>
              </div>
              <h1 className="text-center w-full text-3xl px-5 uppercase hidden 2xl:absolute top-10 left-5 2xl:max-w-[28%] 2xl:block ">
                WHAT IS MY diamond WORTH?
              </h1>
              <p className="text-center 2xl:absolute top-40 2xl:px-5 left-5 2xl:max-w-[28%] 2xl:block">
                Fill out the simple form below to receive a free watch quote!
              </p>

              <div className="flex flex-col space-y-2">
                <div className="flex flex-col space-y-4">
                  <InputField
                    name={"name"}
                    label={"Name"}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={"phone"}
                    label={"Phone"}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={"email"}
                    label={"E-Mail"}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={"carat"}
                    label={"Carat Weight"}
                    form={form}
                    onChange={updateForm}
                  />
                  <InputField
                    name={"clarity"}
                    label={"Clarity"}
                    form={form}
                    onChange={updateForm}
                  />
                  <InputField name={"color"} label={"Color"} form={form} onChange={updateForm} />
                  <InputField
                    name={"description"}
                    label={"Description"}
                    type={"textarea"}
                    rows={"5"}
                    form={form}
                    onChange={updateForm}
                    className={"pm-12"}
                  />
                  <FileUpload
                    id={"photos"}
                    label={"Add photos"}
                    name={"photos"}
                    accept={".jpg,.png,.jpeg"}
                    updateFilesCb={updateUploadedFiles}
                    multiple
                  />
                </div>
                * Max. file size: 40 MB, Max. files: 5.
                <br />
                <br />
                Please attach photos of items & appraisals/certificates. The red 'X's are normal.
              </div>
              <div className="w-full flex items-center justify-center 2xl:justify-start">
                <button className="bg-[#816230] hover:bg-accentMd duration-200 text-white px-7 py-3 rounded-full my-5">
                  SUBMIT FORM
                </button>
              </div>
            </FormWrapper>
          </div>
        </div>
      </section>

      <HowItWorks className={"w-10/12 max-w-[1366px] mx-auto"} />

      <AdditionalForms />

      <div className="max-2-max flex flex-col justify-center w-10/12 max-w-[1366px] mx-auto">
        <p className="w-full p-10 text-lg">
          If anyone types “where to Get a free diamond quote” on any search engine, they should be
          directed to SellUsYourJewelry.com. For over 40 years, SUYJ has dealt with clients across
          the country wanting to “get a free diamond quote”. Whether located near our showroom in
          the heart of Miami or in or in a different out of town, Gray and Sons Jewelers caters to
          all the luxury needs of clients in states such as California, New York, Nevada, Texas,
          Colorado, Georgia, and more.
          <br />
          <br />
          On our buying site, customers anywhere can request a free diamond quote to sell their
          stones without leaving the comfort of their home! By merely submitting images along with a
          description and some basic information, customers can receive an estimate to outright buy
          or to consign their diamonds with us within 48 hours! Our sales specialists have been in
          the business of buying all brands of high-end jewelry and precious stones for over 40
          years so one can rest assured knowing they’re receiving an honorable diamond quote.
          <br />
          <br />
          Once customers receive our offer and have agreed to sell their loose diamond or diamond
          jewelry, they have the option to come into our store and bring in their item or request an
          Easy Ship box to ship the diamond in. In this Easy Shipping box, we supply customers with
          all the necessary instructions and packaging materials to safely transport their items to
          us. Once the item has been re-evaluated and its condition has been inspected in-house, one
          of our sales specialists reach out to confirm the agreed diamond quote and method of
          payment. It’s really that simple to “sell precious diamond jewelry”!
          <br />
          <br />
          Not only is Gray and Sons a center to buy or sell my loose diamond or diamond jewelry, but
          we also do trades and repairs for all jewelry and swiss-made watches. With professional
          polishers, jewelers, and six in-house swiss-trained watchmakers, Gray and Sons Jewelers
          carries the combined experience of over 150 years in the luxury jewelry business. We
          guarantee to give our customers the best diamond quote they’ll receive which is why we ask
          customers to come to see us last. Everyday Gray and Sons purchases new items from clients
          all around, we are always looking to expand our inventory!
          <br />
          <br />
          Come see us to “get a free diamond quote”! We will give you the best offer from all other
          buyers, make sure you come to see us last when looking to “Sell my loose diamond or
          diamond jewelry”. 5 Whether you are interested in selling a loose diamond or one set in a
          ring, pendant or stud earrings, we would love to help you get the most money for it. We
          buy quality diamonds of all cuts and sizes. SellUsYourJewelry.com is the best place to get
          the highest price for your diamond.
        </p>
        <br />
        <Link to="/ez-ship-box-request-form/" className="hoverScale flex w-full justify-center ">
          <StaticImage
            src={"../images/Shipping-Box-request.jpg"}
            alt={"Get an Appraisal for Jewelry - Shipping"}
            loading={"eager"}
          />
        </Link>
        <br />
        <br />{" "}
        <p className="p-10">
          We guarantee that no other high-end jewelry business treats you like we do here at Gray
          and Sons Jewelers. Our intention is to make every single customer feel as if they’re
          dealing with a friend, not a business. We will give you the most honest opinion and advice
          regardless of if we end up doing business or not. Our team knows the importance of
          building a relationship with every single customer and have is strong on building rapport
          with clients. Many of our long term clients have been loyal to our business for over 40
          years, if you don’t believe us just come in and see for yourself!
        </p>
      </div>

      <AsAdvertisedOn />

      <div className="w-full py-10 shadow-lg" />

      <HaveQuestions />

      <section className={"mt-16"}>
        <Map />
      </section>
    </Layout>
  )
}

export default DiamondQuoteForm
